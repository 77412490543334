form {
        sup {
            color: $red;
            font-size: 120%;
            font-weight: bold;
        }

    input {
        &::placeholder {
            color: $dark-gray;
        }
    }

    // 同意ボタン　チェックリスト
    .confirmation {
        fieldset {
            display: flex;
            flex-direction: column;
        }
    }

    hr {
        margin-top: 0;
    }
    .period,
    .position,
    .postal,
    .tel {
        legend {
            font-size: 0.875rem;
        }
        .hyphen {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}