body #main-content .main-area {
        .breadcrumbs {
            margin-top: 2rem;
            margin-left: 1rem;
        li {
            font-size: .9rem;
            &.disabled {
                color: $black;
            }
            &:last-child {
                font-style: italic;
                color: $black;
            }
            &:first-of-type {
                &::before {
                    content:"\f015";
                    font-family: "Font Awesome 5 Free";
                    font-weight: bold;
                    margin-right: .25rem;
                    color: $blue;
                }
            }
        }
    }
}