.label {
    border-radius: 2.5px;
    @include shadow($prototype-box-shadow);
    min-width: 4rem;
    text-align: center;
    margin-right: .5rem;
    font-size: 1rem;
    &.caution {
        background-color: $red;
    }
    &.event {
        background-color: $blue;
    }
}