#main-content {

    // トップページバナー
    .banner-wrapper {
        a {
            text-decoration: none;

            .card {
                .card-image-holder {
                    // background-color: $light-blue;
                    padding: 1rem .8rem;

                    img {
                        max-height: 12rem;
                    }
                }
            }
        }
    }

    // トップページバナー　関連リンク
    .banner-list {
        list-style: none;
        margin-left: 0;
        a {
            text-decoration: none;
            .card {
                flex-direction: row;

                .card-image-container {
                    flex-basis: 50%;
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }

                .card-section {
                    flex-basis: 50%;
                    padding: .25rem;
                    display: flex;
                    align-items: center;
                    padding-left: 1rem;
                    h3 {
                        text-align: left;
                        @include breakpoint (small only){
                            font-size: 2.2rem;
                        }
                    }
                }
                &.shiruporuto-chuou {
                    .card-section {
                        background-color: #FFD164;
                    }
                }
                &.boj-osaka {
                    .card-section {
                        background-color: $blue;
                        color: $white;
                    }
                }
                &.boj-honten {
                    .card-section {
                        background-color: #177faa;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}


// 活動内容バナー
.activity {
    .card {
        .card-image-holder {
            // background-color: rgba($color: $light-green, $alpha: .9);
            // background-image: url(#{$path}assets/img/common/bg_pattern_banner.png);
            // background-repeat: repeat;
            // background-size: 40px;
            // background-blend-mode: lighten;
            padding: .8rem;
            text-align: center;

            img {
                max-height: 15rem;
            }
        }
    }
}