.table-scroll {
    white-space: nowrap;
}

#main-content {
    table {
        thead {
            background-color: $powder-blue;

            th {
                font-weight: normal;
                text-align: center;
                padding: .25rem;
                border: 1px solid $gray;
            }
        }

        tbody {
        border: 1px solid $gray;
            background-color: $white;

            tr:nth-child(odd) {
                background-color: $light-gray;

                & th {
                    background-color: $powder-blue;
                }
            }

            tr:nth-child(even) {
                background-color: $white;

                & th {
                    background-color: $light-blue;
                }
            }

            tr th {
                background-color: $light-blue;
            }

            tr td {
                border-right: 1px solid $gray;
                &:last-of-type {
                    border-right: none;
                }
                ul {
                    margin-bottom: 0;
                }
            }
        }

        &.row-style {
            th {
                text-align: left;
                vertical-align: middle;
            }
        }
    }
}