@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}

body {
  overflow-x: hidden;

  #main-content {
    flex-grow: 1;
    padding-bottom: 5rem;

    a {
      color: $link-blue;
      ;
      text-decoration: underline;

      &:hover {
        color: $link-hover-blue;
      }
    }
  }

}