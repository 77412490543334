#main-content {
    button, .button {
    color: $white;
    text-decoration-line: none;
    border-radius: 5px;
    &:hover {
        color: $white;
    }
}
.btn-push {
    @include shadow($prototype-box-shadow);
    &:hover {
        transform: translate3d(1px, 2px, 0);
    }
}
}