#page_top{
    width: 180px;
    height: 180px;
    position: fixed;
    right: 0;
    bottom: 50px;
    z-index: 100;
    // opacity: 0.6;
        a {
            position: relative;
            display: block;
            width: 180px;
            height: 180px;
            text-decoration: none;
            &::before {
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                content: '\f0aa';
                font-size: 4rem;
                color: $dark-gray;
                position: absolute;
                width: 50px;
                height: 50px;
                top: -40px;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                text-align: center;
            }
        }
  }
