.grid-container.full.main-area {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#main-content {
    background-color:$light-blue;
    position: relative;
    section {
        margin-bottom: 3rem;
    }
}