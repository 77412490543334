* {
    font-feature-settings: "palt"1;
}


h2,
h3,
h4,
h5 {
    font-family: tbchibirgothicplusk-pro, sans-serif;
    font-style: normal;
    letter-spacing: 0.1em;
    font-weight: bold;
}

h2 {
    margin-bottom: 2rem;

    section & {
        border-left: 10px solid $blue;
        padding-left: .25em;
        border-bottom: 2px solid $mid-blue;
        padding-bottom: .25em;
        background-color: $white;
    }

    &.text-center,
    #home & {
        border-left: none;
        border-bottom: none;
        padding-left: 0;
        background-color: transparent;
        text-align: center;
        @include breakpoint(small only) {
            font-size: 1.6rem;
        }
        span {
            background-color: $powder-blue;
            display: inline-block;
            @include breakpoint (small only){
                display: block;
            }
            border-radius: 10px;
            padding: .5rem 1.5rem;
            background-color: #87ceeb;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%235366c6' fill-opacity='0.11'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
            @include shadow($prototype-box-shadow);
            @include breakpoint(small only) {
                padding: .5rem .5rem;
                
            }
        }
    }
}

h3 {
    section & {
        border-left: 15px double $blue;
        padding-left: .25em;
        border-bottom: 1px solid $gray;
    }

    &.text-center,
    section &.text-center {
        margin-bottom: 3rem;
        border: none;

        span {
            padding: .75rem 1.5rem;
            background-color: $blue;
            color: $white;

        }

    }

    #link &.text-center {
        margin-top: 1em;

        span {
            background-color: $white;
            color: $black;
            @include shadow($prototype-box-shadow);
        }
    }
}

h3,
h4,
h5 {
    margin-bottom: 1.5rem;
}

hr {
    margin-top: 5rem;
    border-bottom: 1px solid $gray;
}


// 装飾
.mark-wave {
    text-decoration: wavy underline $gray;
}

.mark-wave-red {
    text-decoration: wavy underline $red;
}

.mark-wave-blue {
    text-decoration: wavy underline $blue;
}

.mark-underline-green {
    text-decoration: double underline $gray;
}

.mark-underline-red {
    text-decoration: underline $red;
}

.border-blue {
    background: linear-gradient(transparent 70%, $blue 80%);
}
// 折り返し設定
p, li {
    word-break: break-all;
}