#main-content {
    .tabs {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        border: none;
        border-radius: 15px 15px 0 0;
        border-bottom: 10px solid lighten($color: $gray, $amount: 10);
        &::before,
        &::after {
            display: none;
        }
        .tabs-title {
            background-color: $dark-gray;
            width: 100%;
            text-align: center;

            &:first-of-type {
                border-radius: 15px 0 0 0;
            }

            &:last-of-type {
                border-radius: 0 15px 0 0;
            }

            &.is-active {
                background-color: lighten($color: $gray, $amount: 10);
            }

            &:hover {
                background-color: lighten($color: $gray, $amount: 15);
                transition: linear 0.2s;
                & a {
                    color: $black;
                }

            }

            a {
                font-size: 1.2rem;
                color: $white;
                font-style: italic;
                text-decoration: none;
                border-radius: 15px;
                margin: .25rem 0;

                &:hover {
                    background-color: inherit;
                    text-decoration: underline;
                    font-weight: bold;
                }
            }

            &.is-active a {
                color: $black;
                font-weight: bold;
                font-style: normal;
                text-decoration: none;
                background-color: transparent;
            }
            &:hover {
                color: $black;
            }
        }
    }

    .tabs-content {
        border: none;

        .tabs-panel {
                background-color: $light-gray;
                border: 1px solid $gray;
                border-radius: 0 0 10px 10px;
            thead,
            tbody {
                border: none;
            }

            thead {
                background-color: transparent;

                th {
                    text-align: center;
                    color: $dark-gray;
                    font-weight: normal;
                    border: none;
                }
            }

            tr {
                border-bottom: 1px solid $gray;

                &:nth-child(even) {
                    background-color: #DDEBF6;
                }
            }
        }
    }
}