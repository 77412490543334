@include breakpoint (medium down) {
    #offCanvas {
        background-color: $light-blue;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        // height: 100vh;
        // min-height: 480px;
        background-color: $powder-blue;
        overflow-y: auto;
        @media (orientation: landscape) {
            // min-height: 800px;
        }
.grid-y.grid-frame {
    height: -webkit-fill-available;
    @media (orientation: landscape) {
        height: inherit;
        overflow-y: auto;
        min-height: 100%;
    }    
    button.close-button {
        background-color: $white;
        width: 1.5rem;
        height: 1.5rem;
        top: 0.5rem;
        line-height: 1;
        font-size: 1rem;
        color: $black;
        border-radius: 5px;
    }
    p.logo-sp-menu {
        margin-top: 3rem;
        .logo {
            width: 100%;
            padding: .25rem .5rem;
        }

    }
.sp-nav-wrapper {
    @media (orientation: landscape){
        min-height: 100%;
    }
    .gNav {
        // flex-direction: column;
        li {
            &.is-accordion-submenu-parent > a {
                &:hover {
                    text-decoration: none;
                }
            }
            a {
                color: #0846AA;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
    .sp-nav-footer {
        background-color: $light-gray;
        .subNav {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            li {
                a {
                    color: $link-blue;
                    text-decoration: underline;
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    }
}
    }
}