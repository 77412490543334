.slick-container {
    // margin-bottom: 3rem;
    position: relative;

    .slick-carousel-wrapper {
        margin: 0;

        button {
            z-index: 30;
            width: 2rem;
            height: 2rem;

            &:hover {
                text-decoration: none;
            }

            &::before {
                color: $white;
                font-size: 2rem;
            }

            &.slick-prev {
                left: 0;
                margin-left: .25rem;
            }

            &.slick-next {
                right: 0;
                margin-right: .25rem;
            }
        }

        .slick-carousel {
            max-height: 35rem;
            position: relative;
                @include breakpoint (medium only){
                        max-height: 24rem;
                }
                    @include breakpoint (small only){
                        max-height: 18rem;
                    }

            figure {
                img {
                    object-fit: cover;
                    width: 100%;
                    max-height: 35rem;
                    object-position: center;
                    @include breakpoint (small only){
                        height: 20rem;
                    }
                }
            }

        }
    }

    .slick-btn {

        z-index: 40;
        position: relative;

        .btn-wrapper {
            position: absolute;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-right: .5rem;

            button {
                margin-top: .5rem;

                &:first-child {
                    margin-right: .5rem;
                }
            }
        }
    }
    // 矢口家画像
    .slick-cartoon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5%;
        @include breakpoint (medium only){
            left: auto;
            right: 10%;
        }
        img {
            max-width: 600px;
            @include breakpoint (medium only) {
                max-width: 400px;
            }
            @include breakpoint (small only) {
                max-width: 200px;
            }
        }
    }
}