footer {
    background-color: $light-gray;
    padding: 2rem 1.5rem 0 1.5rem;
    margin-top: auto;
    margin-bottom: 0;

    .footer-menu-wrapper {
        a {
            text-decoration: underline;
        }
        .footer-menu {

            height: 15rem;
            @include breakpoint (medium only){
                height: 30rem;
            }
            @include breakpoint (small only){
                height: 100%;
            }
          >  .menu.vertical {
                flex-wrap: wrap !important;
                height: 100%;
                
                li {
                    a {
                        color: $link-blue;
                        text-decoration: underline;
                        display: inline-block;
                        &:hover,
                        &:focus {
                            color: $link-hover-blue;
                        }
                    }
                }
            }
        }
    img {
        width: 80px;
    }
    }
}