#main-content {
    .title-header {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 1rem;
        min-height: 20rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @include breakpoint (medium only){
            min-height: 15rem;
        }
        @include breakpoint(small only){
            min-height: 12rem;
        }
        h1 {
            font-family: tbchibirgothicplusk-pro, sans-serif;
            font-size: 5rem;
            background-color: rgba($color: $powder-blue, $alpha: .8);
            padding: .5rem 1rem;
            border-radius: 10px;
            @include breakpoint (medium only){
                font-size: 3.5rem;
            }
            @include breakpoint (small only){
                font-size: 2.5rem;
            }
        }

        &.advisor {
            background-image: url(#{$path}assets/img/common/bg-advisor.jpeg);
        }

        &.education {
            background-image: url(#{$path}assets/img/common/bg-education.jpeg);
            background-position: center bottom;
        }

        &.event {
            background-image: url(#{$path}assets/img/common/bg-event.jpeg);
            background-position: center bottom;
        }

        &.link {
            background-image: url(#{$path}assets/img/common/bg-link.jpeg);
        }

        &.studygroup {
            background-image: url(#{$path}assets/img/common/bg-studygroup.jpeg);
        }

        &.video {
            background-image: url(#{$path}assets/img/common/bg-video.jpeg);
            background-position: center bottom;
        }

        &.a11y {
            background-image: url(#{$path}assets/img/common/bg-accessibility.jpeg);
            background-position: center bottom;
        }

        &.contact {
            background-image: url(#{$path}assets/img/common/bg-contact.jpeg);
        }

        &.privacy {
            background-image: url(#{$path}assets/img/common/bg-privacy.jpeg);
        }

    }
}