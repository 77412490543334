// SP mode
.title-bar.sp-title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 .5rem;

    .top-bar-right {
        img.logo {
            height: 3.5rem;
            padding: .25rem 2rem .25rem 0;
            z-index: 20;
        }
    }
}

// PC mode

header {
    position: relative;
    // background-size: cover;
    // background-position: center bottom;
        // background: linear-gradient(to top, $white, $sky-blue);
        background: $sky-blue;
        background-image: url(#{$path}assets/img/common/header-bg-city.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        overflow: hidden;

    #page-top {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        padding: 1rem .5rem 1rem 1rem;

        .title-logo {
            z-index: 20;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            a {
                .catchcopy {
                    text-align: center;
                    margin: 0;

                    span {
                        background-color: $white;
                        padding: .5rem;
                        border-radius: 10px;
                        border: 1.5px solid #0091D8;
                        display: inline-block;
                        font-weight: bold;
                    }
                }

                .logo {
                    max-width: 20rem;

                    @include breakpoint(medium only) {
                        max-width: 10rem;
                    }
                }
            }
        }

        .header-menu {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            z-index: 20;

        }

    }

    .yaguchi-wrapper {
        // position: absolute;
        top: 0;
        height: 210px;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        img {
            max-width: 50%;
            max-height: 50%;
            z-index: 15;
        }
    }
}