#main-content {
    .accordion-table-wrapper {
        // background-color: $light-green;
        border-radius: 15px;
        li {
            text-align: center;
            a {
                font-size: 1.2rem;
                color: $black;
                &.accordion-title {
                    border-radius: 15px;
                    &::before,
                    &::after
                     {
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 1.4rem;
                    }
                    &::before {
                        content: "\f13a";
                    }
                }
                &.accordion-title:hover,
                &.accordion-title:focus {
                    background-color: inherit;
                }
            }
            .accordion-content {
                border-radius: 15px;
            }
            &.is-active a::before {
                content: "\f139";
            }
        }
    }
}