    .card {
        border-radius: 5px;
        @include shadow($prototype-box-shadow);
        max-width: 27rem;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 4rem;
        .card-section {
            background-color: $light-blue;

            h3 {
                text-align: center;
            }
        }

        &.avatar-card {
            p.lead {
                margin-bottom: 0;
                font-weight: bold;
            }

            li {
                font-size: .8rem;
            }

            .card-section {
                background-color: $light-blue;

                ul {
                    margin-left: .25rem;
                }
            }
        }

        #education & {

            &.elementary {
                background-color: lighten($color: $red, $amount: 52);
            }

            &.juniorhigh {
                background-color: lighten($color: $mint-green, $amount: 5);
            }

            &.highschool {
                background-color: lighten($color: $powder-blue, $amount: 10);
            }

            .card-section {
                background-color: $pale-yellow;
            }
        }

        #home .site-introduction & {
            img {
                flex-shrink: 0;
            }
            .card-section {
                background-color: $cream-orange;
                border-top: 1px solid $gray;
            }

            &.advisor {
                background-color: lighten($color: $mint-green, $amount: 5);
            }

            &.event {
                background-color: lighten($color: $sky-blue, $amount: 12);
            }

            &.education {
                background-color: $pale-yellow;
            }

            &.studygroup {
                background-color: lighten($color: $violet, $amount: 5);
            }

            &.video {
                background-color: lighten($color: $red, $amount: 45);
            }

            &.link {
                background-color: lighten($color: $mint-green, $amount: 3);
            }
        }

        #advisor & {
            .card-section {
                background-color: $cream-orange;

                ul {
                    list-style: none;
                    text-indent: -0.5em;
                    padding-left: .5em;

                    li {
                        &::before {
                            display: inline-block;
                            font-family: "Font Awesome 5 Free";
                            content: "\f00c";
                            font-weight: bold;
                            margin-right: .25em;
                            color: $blue;
                        }
                    }
                }
            }
        }
    }