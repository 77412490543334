.kome {
    list-style-type: none;
    margin-left: 2rem;

    li {
        padding-left: 1rem;
        position: relative;

        &::before {
            position: absolute;
            content: "※";
            top: 0;
            left: 0;
            line-height: 1;
            font-size: 120%;
            color: $red;
        }
    }
}
.asterisk-list {
    list-style-type: none;
    margin-left: 2rem;
    &__right {
        display: flex;
        justify-content: flex-end;
    }
    li {
        padding-left: 1rem;
        position: relative;

        &::before {
            position: absolute;
            content: "*";
            top: 0;
            left: 0;
            line-height: 1;
            font-size: 120%;
            color: $red;
        }
    }
}
.icon-list {
    margin-left: 2.5rem;
    list-style-type: none;

    li {
        margin-bottom: 1rem;
        text-indent: -0.8rem;
        margin-left: 1rem;

        >i {
            &:first-child {
                margin-right: .5rem;
            }

            &:not(:first-child) {
                text-indent: 0;
            }
        }

        a+span {
            position: relative;

            >i {
                position: absolute;
                top: 0;
                left: 1rem;
            }

            margin-right: 1rem;
        }
    }
}

// dl インデント調整
.dl-indent {
    padding-left: 1.5em;

    @include breakpoint(small only) {
        padding-left: 1em;
    }

    dd {
        margin-left: 3em;

        @include breakpoint(small only) {
            margin-left: 1em;
        }
    }
}

// dl 横並び
.dl-horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 2rem;

    dt {
        width: 20%;
    }

    dd {
        width: 80%;
        line-height: 1.8;
    }

    @include breakpoint (small only) {

        dt,
        dd {
            width: 100%;
        }

        dd {
            padding-left: 2rem;
        }
    }
}

// New Release!
.indent-line {
    list-style: none;
    margin-left: 4.5rem;
    padding-left: 0;

    >li {
        position: relative;

        &::before {
            position: absolute;
            content: "\2e3b";
            display: block;
            left: -3.5rem;
        }
    }
}

// 丸数字
.circular-num {
    counter-reset: my-counter;
    list-style: none;
    padding-left: 0;

    >li {
        font-size: 1rem;
        line-height: 1.5;
        padding-left: 1.5rem;
        position: relative;

        &::before {
            content: counter(my-counter);
            counter-increment: my-counter;
            background-color: $white;
            border: 1px solid;
            border-radius: 50%;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 1.2rem;
            width: 1.2rem;
            color: $black;
            font-size: 80%;
            line-height: 1;
            position: absolute;
            top: .15rem;
            left: 0;
        }
    }
}

// カッコ数字
.list-bracket-num {
    list-style: none;

    li {
        list-style-position: inside;
        counter-increment: cnt;
        text-indent: -1.8em;

        &::before {
            display: -moz-marker;
            display: marker;
            content: "（"counter(cnt) "）";
        }
    }
}




// チェックリスト
.check-list {
    list-style: none;
    margin-left: 2.5rem;

    li {
        line-height: 2;

        &::before {
            display: inline-block;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 1.4rem;
            color: $blue;
            content: "\f058";
            margin-right: .5rem;
            font-size: 100%;
        }
    }
}

// 無印
.no-deco {
    list-style: none;
    margin-left: 0;
}

// アイコン用ネガティブインデント
.icon-indent {
    text-indent: -0.5em;
    padding-left: 1em;
}

// バナーリスト
.panel-list {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
    justify-content: space-around;
    li {
        padding: .5rem;

        // margin-bottom: 2rem;
        a {
            background-color: lighten($color: $blue, $amount: 40%);
            padding: .5rem;
            margin: .5rem;
        }
    }

    #link & {
        @include breakpoint (small only) {
            flex-direction: column;
            align-items: stretch;
        }

        li {
            margin-bottom: 2.5rem;

            // width: 100%;
            @include breakpoint (small only) {
                display: flex;
                align-items: stretch;
                margin-bottom: 0;
                padding: 0;
            }

            a {
                padding: 1rem .5rem;
                border-radius: 5px;
                @include shadow($prototype-box-shadow);
                background-color: $blue;
                color: $white;
                position: relative;
                text-decoration: none;
                &:hover {
                    background-color:lighten($color: $blue, $amount: 15);
                    // color: $black;
                }
                &::before {
                    content: "\f35a";
                    font-family: "Font Awesome 5 Free";
                    vertical-align: middle;
                    font-size: 1.5rem;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    font-weight: bold;
                    margin-right: .5rem;
                }

                @include breakpoint (small only) {
                    width: 100%;
                }
            }
        }
    }
}

// ニュース一覧リスト
.info-list {
    list-style: none;
    display: flex;
    justify-content: flex-end;

    li {
        font-size: 1.2rem;
    }
}

// リンクリスト
.link-list {
    list-style: none;

    li {
        &::before {
            content: "\f138";
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            color: $blue;
            margin-right: .25rem;
        }
    }
}

// 画像リスト
.piclist {
    list-style: none;

    li {
        margin-bottom: 1.5em;

        img {
            width: 100%;
        }
    }
}
// チェックリスト
.check-list {

    li {
        &::before {
            content: "\f14a";
            font-family: "Font Awesome 5 Free";
            font-weight: normal;
            color: $blue;
            margin-right: .25rem;
        }
    }    
}
// 装飾用リスト
.deco-simple {
    list-style: none;
    li {
        padding-left: 1em;
        position: relative;
        &::before {
            position: absolute;
            content:"\2b25";
            left: 0;
            color: $blue;
        }
    }
}