// 新着情報
#main-content {
    .callout {
        &.home {

            max-height: 25rem;
            overflow-y: scroll;
        }
        .whats-new-list {
            list-style: none;
            li {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                border-bottom: 1px solid $gray;
                padding: .5rem 0;
                &:first-of-type {
                    border-top: 1px solid $gray;
                }
                .date {
                    width: 8rem;
                    padding: 0 .5rem;
                }
                .whats-new-list-title {
                    flex-basis: 75%;
                }
            }
        }
    }
}