$blue : #324297;
$light-blue : #e6f2ff;
$mid-blue : #5366c6;
$sky-blue : #87ceeb;
$powder-blue : #a1c3e6;
$link-blue : #035B96;
$link-hover-blue : #0374BF;
$gray : #bebebe;
$light-gray : #F5F5F5;
$dark-gray : #707070;
$red : #e00000;
$white : #fefefe;
$pale-yellow : #ffffe6;
$cream-orange : #fff0e6;
$gold : #d2cf79;
$green : #04532D;
$mint-green : #C9FED9;
$violet : #ebcbfe;
.blue {
    color: $blue;
}
.green {
    color: $green;
}
.white {
    color: $white;
}
.red {
    color: $red;
}
// background-color
.bg-gray {
    background-color: $gray;
}